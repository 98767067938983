export default {
  NOTE_COUNTRY: 'Livraison en France uniquement.',
  ERR_EMAIL_EXISTS: 'Un compte client existe déjà avec cet email.',
  MSG_THIRD_PARTY_NOTICE:
    'Les informations nominatives recueillies au moment de votre inscription sont confidentielles et ne seront ni cédées ni louées ni vendues à des tiers.',
  VIEW_MORE: 'Détails',
  EMAIL_DELIVERY: 'Livraison par e-mail',
} as const;

export const SHIPPING_TYPES = {
  HOME: 'A domicile',
  STORE: 'En magasin',
  PICKUP: 'En point relais',
};

export const ORDER_STATUS = {
  CANCELED: 'Annulée',
  PROCESSING: 'En préparation',
  PENDING: 'En attente',
  PENDING_PAYMENT: 'En attente de paiement',
  PAID: 'Payée',
  PARTIAL_REFUND: 'Remboursement partiel',
  REFUNDED: 'Remboursée',
  SHIPPED: 'Expédiée',
  REMOVED: 'Supprimée',
  NEW: 'Nouvelle',
  PACKED: 'En préparation',
  BAGGED: 'En préparation',
  DISPATCHED: 'Expédiée',
  RECEIVED: 'Reçue',
  COLLECTED: 'Collectée',
  'IN-TRANSIT': 'En transit',
};

export const LBL_ERROR_JWT_TITLE = `Oups...`;
export const LBL_ERROR_JWT_CONTENT = `Bonjour chère cliente Darjeeling, votre session a expiré.`;
export const LBL_ERROR_JWT_ADVICE = `Veuillez vous connecter en cliquant sur le bouton ci dessous`;
export const LBL_ERROR_JWT_BUTTON_CONTENT = 'Se connecter';
export const LBL_ACCOUNT_DELETED_TITLE = `Bonjour chère cliente Darjeeling,`;
export const LBL_ACCOUNT_DELETED_CONTENT = `Votre compte a été supprimé.`;
export const LBL_ACCOUNT_DELETED_ADVICE = `Veuillez vous inscrire.`;
export const LBL_ACCOUNT_DELETED_BUTTON_CONTENT = `Créer un compte`;
