export const LBL_IS_DIFFERENT_ADDRESS = 'Utiliser une adresse de facturation différente';
export const TITLE_BILLING_ADDRESS = 'Adresse de facturation';
export const LBL_IS_GIFT = 'Mon achat est un cadeau';
export const PLACEHOLDER_GIFT_MESSAGE = 'Ecrire un message';
export const STORE_SEARCH_TITLE = 'Recherchez la boutique près de chez vous';
export const HOME_DELIVERY_INFO = 'Votre colis sera livré par';
export const LBL_COLISSIMO = 'colissimo';
export const ADD_NEW_ADDRESS = 'Ajouter une nouvelle adresse';
export const CTA_CANCEL_CONTENT = 'Annuler';
export const PERSONAL_MESSAGE = 'Message personnalisé';
export const LBL_COUNTRY_FRANCE = 'France';
export const CART_CTA_ADDRESS_BTN_CONTENT = ({
  isUpdatingAddress,
}: {
  isUpdatingAddress: boolean;
}): [string, string, string] => {
  return [!isUpdatingAddress ? 'Valider mon adresse' : 'Modifier mon adresse', '', ''];
};
export const LBL_GET_ESTIMATION_DATE = (dateStart: string, dateEnd: string): string => {
  return `Prévue entre le ${dateStart} et le ${dateEnd}`;
};
export const LBL_NO_ADDRESS_RECORDED = `Vous n'avez pas encore d'adresse française enregistrée.`;
