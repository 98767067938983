import { ShippingTypes } from '../cart/types';
import { PickupStation } from '../pickup/types';

export type State = {
  isFetching: boolean;
  errMsg: string;
  user?: User;
  orders: Order[];
  order?: OrderDetail;
};

export type Order = {
  orderNumber: number;
  status: OrderStatus;
  createdAt: string;
  total: number;
  migrated: boolean;
  tracking?: string[];
  trackingNumber?: string;
  shippingType?: ShippingTypes;
};

export type OrderDetail = {
  orderNumber: number;
  createdAt: string;
  status: OrderStatus;
  shippingAddress: Address;
  billingAddress: Address;
  items: OrderItem[];
  storeInfos: StoreInfos;
  pickupStationInfos?: PickupStation;
  total: number;
  originalBaseTotal: number;
  totalCouponAmount: number;
  totalDiscount: number;
  shippingAmount: number;
  isDigital: boolean;
  tracking?: Tracking[];
  trackingNumber?: string;
  shippingType?: ShippingTypes;
  paymentMethod?: string;
  cardSummary?: string;
  paymentDetails?: PaymentInfo[];
};

export type Address = {
  id: string;
  name: string;
  company: string;
  idTva: string;
  siret: string;
  firstName: string;
  lastName: string;
  street: string;
  streetAdditional: string;
  postal: string;
  city: string;
  phone: string;
  country: string;
};

export type OrderItem = {
  productRef: string;
  colorRef: string;
  sku: string;
  productName: string;
  detailedColorLabel: string;
  rowBaseTotal: number;
  rowTotal: number;
  quantity: number;
  size: string;
  bandSize: string;
  cupSize: string;
  tax?: number;
};

export type OrderSummary = {
  subtotal: string;
  promotion: string;
  shipping: string;
  total: string;
};

export type StoreInfos = {
  address1: string;
  address2: string;
  city: string;
  countryCode: string;
  id: number;
  name: string;
  postalCode: string;
};

export type Tracking = {
  trackingNumber: string;
};

export type PaymentInfo = {
  method: string;
  cardSummary: string;
  amount: string;
};

export enum OrderStatus {
  CANCELED = 'CANCELED',
  PROCESSING = 'PROCESSING',
  PENDING = 'PENDING',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PAID = 'PAID',
  PARTIAL_REFUND = 'PARTIAL_REFUND',
  REFUNDED = 'REFUNDED',
  SHIPPED = 'SHIPPED',
  REMOVED = 'REMOVED',
  NEW = 'NEW',
  PACKED = 'PACKED',
  BAGGED = 'BAGGED',
  DISPATCHED = 'DISPATCHED',
  RECEIVED = 'RECEIVED',
  COLLECTED = 'COLLECTED',
  'IN-TRANSIT' = 'IN-TRANSIT',
}

export type User = {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  dob?: string;
  prefix?: string;
  optInSMS: boolean;
  optInEmail: boolean;
  createdAt: string;
  addresses: Address[];
  emailSHA256: string;
  emailMD5: string;
  shippingAddressId?: string;
  billingAddressId?: string;
  lastOrderDate?: string;
  error?: string;
};
