import {
  Store,
  Middleware,
  applyMiddleware,
  compose,
  createStore,
  Dispatch,
  UnknownAction,
} from 'redux';
import thunk from 'redux-thunk';
import {
  createStateSyncMiddleware,
  initMessageListener,
  withReduxStateSync,
} from 'redux-state-sync';

import { STATE_SYNC_CONFIG } from './config';
import rootReducer from './rootReducer';
import { getIsClient } from '../modules/common/utils';

declare global {
  interface Window {
    __PRELOADED_STATE__: any;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const configureStore = () => {
  const isClient = getIsClient();

  const middleware: [Middleware] = [thunk];

  let state = {};

  if (isClient) {
    const sync = createStateSyncMiddleware(STATE_SYNC_CONFIG);
    middleware.push(sync as unknown as Middleware<{}, any, Dispatch<UnknownAction>>);
    state = window.__PRELOADED_STATE__;
    delete window.__PRELOADED_STATE__;
  }

  const composeEnhancers =
    process.env.DEPLOYMENT_ENV !== 'production' &&
    isClient &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const enhancer = composeEnhancers(applyMiddleware(...middleware));

  let store: Store;

  if (isClient) {
    const reduces = withReduxStateSync(rootReducer);
    store = createStore(reduces, state, enhancer);
    initMessageListener(store);
  } else {
    store = createStore(rootReducer, state, enhancer);
  }

  return store;
};

export default configureStore;
