import React, { useEffect, FormEvent } from 'react';
import { UnknownAction } from 'redux';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import {
  DynamicButton,
  Field,
  PasswordField,
  Checkbox,
  DynamicButtonStatus,
  Alert,
  StyledLink,
  breakpoints,
} from '../../../design-system';
import {
  CTA_CONTENT_SIGNUP,
  NOTE_PASSWORD,
  MSG_NEWSLETTER_CHECKBOX,
  PREFILL_HASH,
  AUTH_BAR_SIGNUP_TITLE,
  AUTH_BAR_TITLE,
  ERR_AUTH,
  ERR_ACCOUNT_CREATION,
  MSG_MODIFY,
  MSG_HERE,
  MSG_ERROR,
  CTA_CONTENT_SIGNIN,
  MSG_OR_SUBSCRIBE_WITH,
} from '../locale';
import withForm from '../../form';
import { Forms, FormSignUp, FieldCallback } from '../../form/types';
import { WithFormActions } from '../../form/hocs/withForm';
import { ParamsSignUp } from '../types';
import { isCtaActive, validate } from '../../form/utils';
import SocialLogin from './SocialLogin';
import useInteractionLock from '../../common/hooks/useInteractionLock';
import { RootState } from 'src/shared/store/rootReducer';
import { checkEmail, errorSignup } from '../actions';
import { changeTitle } from '../../common/actions';
import {
  colors,
  durations,
  opacities,
  theme,
} from '../../../design-system/systemprovider/constants';
import paths from '../../routing/paths';

type Props = {
  signup: (params: ParamsSignUp) => void;
  email: string;
  errSignup: string;
  errSocial: string;
  form: FormSignUp;
} & WithFormActions;

const StyledSignUpContainer = styled.div`
  display: grid;
  gap: ${theme.space.l};

  .siginup-error,
  .social-login-alert,
  .error-social-login,
  .signup-user-email {
    font-size: 1.2rem;
    color: ${colors.MAIN_GREY};
    margin: 0;
  }

  .signup-user-email {
    font-size: 1.4rem;
  }

  .email-and-modify-button-block {
    display: flex;
    gap: ${theme.space.m};
  }

  .signup-modify-email-buton {
    padding: 0;
    border: none;
    background-color: unset;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 6px;
    color: ${colors.BLACK};
    font-size: 1.4rem;
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
      }
    }
  }

  .signup-form-block {
    display: grid;
    gap: ${theme.space.l};
  }

  .checkbox-content__description {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin: 0;

    @media (min-width: ${breakpoints.S}px) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
`;

const SignUp = ({
  signup,
  errSignup,
  errSocial,
  form,
  setFormValues,
  setFormValidation,
  email,
}: Props) => {
  const { hash } = useLocation();
  const dispatch = useDispatch();
  useInteractionLock(form.ctaState === DynamicButtonStatus.Loading);
  const { eReservation } = useSelector((state: RootState) => state.form);
  const { ok, message } = useSelector((state: RootState) => state.form.socialLogin.feedback);

  useEffect(() => {
    if (hash === PREFILL_HASH) {
      const userContact = {
        firstName: eReservation?.values?.firstName || '',
        lastName: eReservation?.values?.lastName || '',
        email: eReservation?.values?.email || '',
      };
      setFormValidation({
        form: Forms.signUp,
        values: Object.keys(userContact).reduce((acc, cVal) => {
          return { ...acc, [cVal]: validateField({ key: cVal, value: userContact[cVal] }) };
        }, {}),
      });

      setFormValues({
        form: Forms.signUp,
        values: Object.keys(userContact).reduce((acc, cVal) => {
          return { ...acc, [cVal]: userContact[cVal] };
        }, {}),
      });
    }
  }, [hash]);

  const validateField = ({ key, value }: FieldCallback): string =>
    validate({ value: String(value), key });

  const handleChange = ({ key, value }: FieldCallback) => {
    if (form.validation[key]) {
      setFormValidation({ form: Forms.signUp, values: { [key]: validateField({ key, value }) } });
    }

    setFormValues({ form: Forms.signUp, values: { [key]: value } });
  };

  const validateForm = (): boolean => {
    const { values } = form;
    const newErrMsgs = form.validation;
    const errMsgs = Object.keys(values).map((key) => {
      const errMsg = validateField({ key, value: values[key] });
      newErrMsgs[key] = errMsg;
      setFormValidation({ form: Forms.signUp, values: { [key]: errMsg } });
      return errMsg;
    });
    return errMsgs.every((errMsg) => !errMsg);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { password, firstName, lastName, optInEmail } = form.values;
    if (validateForm()) {
      signup({
        email,
        password,
        firstName,
        lastName,
        optInEmail,
      });
    }
  };

  const onOptInClick = () => {
    setFormValues({
      form: Forms.signUp,
      values: {
        optInEmail: !form.values.optInEmail,
      },
    });
  };

  const onModifyEmailButtonClick = () => {
    dispatch(changeTitle(AUTH_BAR_TITLE));
    dispatch(checkEmail(null) as unknown as UnknownAction);
    dispatch(errorSignup(''));
  };

  useEffect(() => {
    handleChange({ key: 'email', value: email });
  }, [email]);

  useEffect(() => {
    dispatch(changeTitle(AUTH_BAR_SIGNUP_TITLE));
    return () => {
      handleChange({ key: 'firstName', value: '' });
      handleChange({ key: 'lastName', value: '' });
      handleChange({ key: 'password', value: '' });
      setFormValidation({
        form: Forms.signUp,
        values: { firstName: '', lastName: '', password: '' },
      });
    };
  }, []);

  return (
    <StyledSignUpContainer>
      {errSignup && (
        <Alert type="error" title={MSG_ERROR}>
          <p id="signup-error" className="siginup-error">
            {errSignup === ERR_AUTH ? (
              <>
                {ERR_ACCOUNT_CREATION}
                <StyledLink
                  id="customer-service-error-link"
                  to={paths.CONTACT}
                  preset="caption"
                  data-testid="error-link-to-contact-page"
                  aria-label="Lien vers la page contact"
                >
                  {MSG_HERE}
                </StyledLink>
                {` .`}
              </>
            ) : (
              errSignup
            )}
          </p>
        </Alert>
      )}
      {message && (
        <Alert type={ok ? 'success' : 'error'} title={ok ? CTA_CONTENT_SIGNIN[0] : MSG_ERROR}>
          <p id="social-login-alert" className="social-login-alert">
            {message}
          </p>
          {errSocial && (
            <p id="error-social-login" className="error-social-login">
              {errSocial}
            </p>
          )}
        </Alert>
      )}
      <div className="email-and-modify-button-block">
        <p className="signup-user-email">{email}</p>
        <button
          id="signup-modify-email-buton"
          data-testid="signup-modify-email-buton"
          className="signup-modify-email-buton"
          type="button"
          onClick={onModifyEmailButtonClick}
        >
          {MSG_MODIFY}
        </button>
      </div>
      <form className="signup-form-block" onSubmit={handleSubmit}>
        <Field
          onChange={(value) => handleChange({ key: 'firstName', value })}
          errMsg={form.validation.firstName}
          value={form.values.firstName}
          label="Prénom*"
          id="signup-firstName"
          autoComplete="given-name"
          autoFocus
        />
        <Field
          onChange={(value) => handleChange({ key: 'lastName', value })}
          errMsg={form.validation.lastName}
          value={form.values.lastName}
          label="Nom*"
          id="signup-lastName"
          autoComplete="family-name"
        />
        <PasswordField
          onChange={(value) => handleChange({ key: 'password', value })}
          errMsg={form.validation.password}
          value={form.values.password}
          note={NOTE_PASSWORD}
          id="signup-password"
          autoComplete="new-password"
        />
        <Checkbox
          id="signup-optin-email"
          data-testid={`signup-optin-email-checkbox-${form.values.optInEmail ? '' : 'un'}checked`}
          checked={form.values.optInEmail}
          onChange={onOptInClick}
        >
          <div className="checkbox-content__container">
            <p className="checkbox-content__description">{MSG_NEWSLETTER_CHECKBOX}</p>
          </div>
        </Checkbox>
        <DynamicButton
          type="submit"
          id="btn-submit-signup"
          data-testid="btn-submit-signup"
          data={CTA_CONTENT_SIGNUP}
          feedback={form.ctaState}
          disabled={!isCtaActive(form)}
        />
      </form>
      <SocialLogin title={MSG_OR_SUBSCRIBE_WITH} />
    </StyledSignUpContainer>
  );
};

export const SignUpWithForm = withForm(SignUp, Forms.signUp);
