import uniqBy from 'ramda/src/uniqBy';
import * as qs from 'qs';

import { RefinementValue } from '../filters/types';
import { BreadcrumbsItems, CatalogProduct, Tiles } from './types';
import paths from '../routing/paths';
import { CmsCatalogBanner, CmsPromotion, CmsSliderItem } from '../cms/types';
import { capitalize } from '../ecart/utils';
import { colors } from '../../design-system';

export const getRefinement = (items) => {
  const currentRefinementsGroup = {};

  const currentRefinements = uniqBy(
    (arg: { label: string; value: RefinementValue }) => arg.label,
    (items ?? []).reduce((total, current) => {
      const { currentRefinement, value } = current;
      currentRefinementsGroup[current.attribute] = {
        currentRefinement,
        value,
      };
      return Array.isArray(current.items)
        ? [...total, ...current.items]
        : current.currentRefinement.min
          ? [
              ...total,
              ...[
                {
                  label: `${current.currentRefinement.min}€ - ${current.currentRefinement.max}€`,
                  value: current.value,
                },
              ],
            ]
          : total;
    }, [])
  );

  return { currentRefinementsGroup, currentRefinements };
};

export function toProperCase(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function removeAccents(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function getCatalogValue(name: string) {
  return name ? removeAccents(name.replace(/\s/g, '+').toLocaleLowerCase()) : '';
}

export function getCatalogLabel(name: string) {
  return name ? toProperCase(name.replace(/\+/g, ' ').toLowerCase()) : '';
}

export function getBreadcrumbItems(items: string[]): BreadcrumbsItems {
  return [
    { label: getCatalogLabel(items[0]), value: `${paths.CATALOG}/${getCatalogValue(items[0])}` },
    {
      label: getCatalogLabel(items[1]),
      value: `${paths.CATALOG}/${getCatalogValue(items[0])}/${getCatalogValue(items[1])}`,
    },
    {
      label: getCatalogLabel(items[2]),
      value: `${paths.CATALOG}/${getCatalogValue(items[0])}/${getCatalogValue(
        items[1]
      )}/${getCatalogValue(items[2])}`,
    },
    {
      label: getCatalogLabel(items[3]),
      value: `${paths.CATALOG}/${getCatalogValue(items[0])}/${getCatalogValue(
        items[1]
      )}/${getCatalogValue(items[2])}/${getCatalogValue(items[3])}`,
    },
  ].filter((item) => Boolean(item.label));
}

export function getCatalogId(ids: string[]): string {
  return removeAccents(
    (ids ?? [])
      .reduce(
        (accumulator, id) => (id ? `${accumulator}_${id.replace(/\+/g, '_')}` : accumulator),
        ''
      )
      .substring(1)
      .toLowerCase()
  );
}

export function getCatalogName(ids: string[]): string {
  return (ids ?? []).reduce((accumulator, id) =>
    id ? `${accumulator} - ${getCatalogLabel(id)}` : accumulator
  );
}

const positions = ['left', 'right'];

function sortHalves(a: CmsCatalogBanner, b: CmsCatalogBanner): number {
  const indexA = positions.indexOf(a.banner_column_position ?? '');
  const indexB = positions.indexOf(b.banner_column_position ?? '');
  return indexA - indexB;
}

function getActiveBanners(banners: CmsCatalogBanner[], isMobile: boolean): CmsCatalogBanner[] {
  return banners
    .filter(
      (banner) =>
        banner.is_banner_active &&
        banner.banner_type &&
        banner.banner_row &&
        (banner.banner_row ?? 0) > 0 &&
        (!['one tile', 'two tiles'].includes(banner.banner_type) ||
          (banner.banner_column && Number.parseInt(banner.banner_column, 10) > 0))
    )
    .sort((a, b) => {
      const rowA = isMobile && a.banner_row_mobile ? a.banner_row_mobile : (a.banner_row ?? 0);
      const rowB = isMobile && b.banner_row_mobile ? b.banner_row_mobile : (b.banner_row ?? 0);
      if (rowA < 1 || rowB < 1) {
        return 0;
      }
      if (rowA === rowB) {
        return sortHalves(a, b);
      }
      return isMobile
        ? (a.banner_row_mobile ?? a.banner_row ?? 0) - (b.banner_row_mobile ?? b.banner_row ?? 0)
        : (a.banner_row ?? 0) - (b.banner_row ?? 0);
    });
}

export function getHitsWithBanners({
  hits,
  banners,
  numberOfColumns,
  isMobile,
  hasMore,
}: {
  hits: CatalogProduct[];
  banners: CmsCatalogBanner[];
  numberOfColumns: number;
  isMobile: boolean;
  hasMore: boolean;
}): (CatalogProduct | CmsCatalogBanner)[] {
  let hitsWithBanners: (CatalogProduct | CmsCatalogBanner)[] = [...hits];
  let numberOfTilesSkipped = 0;

  const activeBanners = getActiveBanners(banners, isMobile);
  activeBanners.forEach((banner) => {
    const { banner_row, banner_row_mobile, banner_column, banner_type, banner_column_position } =
      banner;

    const row = isMobile && banner_row_mobile ? banner_row_mobile : (banner_row ?? 0);
    const bannerColumn = Number.parseInt(banner_column ?? '', 10);

    const column =
      banner_type === 'one tile'
        ? numberOfColumns < bannerColumn
          ? numberOfColumns
          : bannerColumn
        : banner_type === 'two tiles'
          ? numberOfColumns <= bannerColumn
            ? numberOfColumns - 1
            : bannerColumn
          : banner_type === 'full column' || banner_type === 'full with original ratio'
            ? 1
            : banner_column_position === 'left'
              ? 1
              : 2;

    const bannerIndex = (row - 1) * numberOfColumns + column - 1 - numberOfTilesSkipped;

    if (
      (hitsWithBanners[bannerIndex] as CmsCatalogBanner)?.is_banner_active ||
      (bannerIndex > hitsWithBanners.length && hasMore)
    ) {
      return;
    }

    hitsWithBanners = [
      ...hitsWithBanners.slice(0, bannerIndex),
      banner,
      ...hitsWithBanners.slice(bannerIndex, hitsWithBanners.length),
    ];

    numberOfTilesSkipped =
      numberOfTilesSkipped +
      (banner_type === 'full column' || banner_type === 'full with original ratio'
        ? numberOfColumns - 1
        : (banner_type === 'half column' || banner_type === 'half with original ratio') &&
            banner_column_position === 'right'
          ? numberOfColumns - 2
          : banner_type === 'two tiles'
            ? 1
            : 0);
  });
  return hitsWithBanners;
}

export function parseSearch(search: string) {
  return qs.parse(search.slice(1)) ?? {};
}

export function isFilteredOrSorted(search: string): boolean {
  const parsedSearch = parseSearch(search);
  const { query, remise, taille, couleur, forme, collection, minPrix, maxPrix, trierPar } =
    parsedSearch;

  return Boolean(
    query || remise || taille || couleur || forme || collection || minPrix || maxPrix || trierPar
  );
}

export function getPage(search: string): number {
  const parsedSearch = parseSearch(search);
  return Number.parseInt(parsedSearch?.page ?? '1', 10);
}

export function getPromotionColors({
  cmsContent,
  promotionPercentage,
  promotionTextColor,
  promotionBackgroundColor,
  isOutlet,
}: {
  cmsContent?: CmsPromotion;
  promotionPercentage?: string;
  promotionTextColor?: string;
  promotionBackgroundColor?: string;
  isOutlet?: boolean;
}): { textColor: string; backgroundColor: string } {
  const { discount_percentages, label_text_color, label_background_color } = cmsContent ?? {};

  const { percentageTextColor, percentageBackgroundColor } = getPercentageColors({
    discount_percentages,
    promotionPercentage,
  });

  return {
    textColor:
      promotionPercentage && !isOutlet
        ? percentageTextColor
        : promotionTextColor || label_text_color || '',
    backgroundColor:
      promotionPercentage && !isOutlet
        ? percentageBackgroundColor
        : promotionBackgroundColor || label_background_color || '',
  };
}

export function getPercentageColors({
  discount_percentages,
  promotionPercentage,
}: {
  discount_percentages?: {
    text_color?: string | undefined;
    background_color?: string | undefined;
  }[];
  promotionPercentage?: string;
}): { percentageTextColor: string; percentageBackgroundColor: string } {
  const numberOfPercentages = discount_percentages?.length;
  if (
    discount_percentages &&
    numberOfPercentages &&
    numberOfPercentages > 0 &&
    promotionPercentage
  ) {
    const increments = 100 / numberOfPercentages;
    const numberDiscountPercentage = Number.parseFloat(promotionPercentage);
    const indexAtPercentages = Math.trunc(numberDiscountPercentage / increments);
    const { text_color, background_color } =
      discount_percentages[
        indexAtPercentages > numberOfPercentages - 1 ? numberOfPercentages - 1 : indexAtPercentages
      ] ?? {};
    return {
      percentageTextColor: text_color || '',
      percentageBackgroundColor: background_color || '',
    };
  }
  return { percentageTextColor: '', percentageBackgroundColor: '' };
}

export const getGridColumn = ({
  type,
  colIndex = '1',
  colPosition = 'left',
  currentTile,
  isLargeDesktop,
}: {
  type: CmsCatalogBanner['banner_type'];
  colIndex?: CmsCatalogBanner['banner_column'];
  colPosition?: CmsCatalogBanner['banner_column_position'];
  currentTile: Tiles;
  isLargeDesktop: boolean;
}) => {
  const numberOfColumns = isLargeDesktop ? (currentTile === Tiles.SMALL ? 4 : 3) : 2;
  const colStart = parseInt(colIndex || '1', 10);

  const colGridStart =
    type === 'two tiles'
      ? colStart >= numberOfColumns
        ? colStart - 1 - (colStart - numberOfColumns)
        : colStart
      : colStart > numberOfColumns
        ? numberOfColumns
        : colStart;
  const colGridEnd =
    type === 'two tiles'
      ? colStart + 2 > numberOfColumns
        ? numberOfColumns + 1
        : colStart + 2
      : colStart + 1 > numberOfColumns
        ? -1
        : colStart + 1;

  switch (type) {
    case 'one tile':
      return `${colGridStart} / ${colGridEnd}`;
    case 'two tiles':
      return `${colGridStart} / ${colGridEnd}`;
    case 'full column':
    case 'full with original ratio':
    case 'highlighted':
      return '1 / -1';
    case 'half column':
    case 'half with original ratio':
      return colPosition === 'right' ? '-3 / -1' : '1 / 3';
    default:
      return `${colGridStart} / ${colGridEnd}`;
  }
};

export const findItemToActivate = ({
  items,
  pathname,
  hash,
  setActiveIndex,
}: {
  items: CmsSliderItem[];
  pathname: string;
  hash: string;
  setActiveIndex: (value: number) => void;
}): void => {
  items.forEach((item: CmsSliderItem, index: number) => {
    if (item.slider_with_number_link?.url) {
      try {
        const itemUrl = new URL(item.slider_with_number_link.url);
        const canBeActive = itemUrl.pathname === pathname;
        if (itemUrl.href.includes(hash) && canBeActive) {
          setActiveIndex(index);
        }
      } catch {
        // Do nothing intentionally
      }
    }
  });
};

export const getMetaTitleForPLP = ({
  name,
  page,
  seotitle,
  defaultTitle,
}: {
  name?: string;
  page: number;
  seotitle?: string;
  defaultTitle: string;
}): string => {
  if (seotitle) {
    if (page > 1) {
      const [firstPart, secondPart] = seotitle.split('|');
      return `${firstPart}- Page ${page} |${secondPart}`;
    }
    return seotitle;
  }
  if (name) {
    const formatedName = capitalize(name.replace(/[+-]/g, ' '));
    if (page > 1) {
      return `${formatedName} - Page ${page} | ${defaultTitle}`;
    }
    return `${formatedName} | ${defaultTitle}`;
  }
  if (page > 1) {
    return `Catalogue - Page ${page} | ${defaultTitle}`;
  }
  return `Catalogue | ${defaultTitle}`;
};

export const getColorBorder = ({
  isSelected,
  isWhitish,
}: {
  isSelected: boolean;
  isWhitish: boolean;
}): string => {
  if (isSelected) {
    return `1px solid ${colors.BLACK}`;
  }
  if (isWhitish) {
    return `1px solid ${colors.LIGHT}`;
  }
  return 'none';
};
