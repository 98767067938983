import React, { useState, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';

import { Forms } from '../../form/types';
import { MAP_BOX_SEARCH_ACCESS_TOKEN } from '../../api/constants';
import { useAddressAutofillModule } from '../../common/hooks/useAddressAutofillModule';

type Props = {
  form: Forms;
  handleFieldChange: ({ form, key, value }: { form: Forms; key: string; value: string }) => void;
  content: () => JSX.Element;
};

const StyledAddressAutocompleteWrapper = styled.div`
  grid-area: street;
`;

export const AddressAutocomplete = ({ form, handleFieldChange, content }: Props) => {
  const [token, setToken] = useState('');
  const { module, isLoading } = useAddressAutofillModule();

  useEffect(() => {
    setToken(MAP_BOX_SEARCH_ACCESS_TOKEN);
    if (module?.config) {
      module.config.accessToken = MAP_BOX_SEARCH_ACCESS_TOKEN;
    }
  }, [module]);

  const handleRetrieve = useCallback(
    (res) => {
      const {
        features: [feature],
      } = res;
      if (feature) {
        const { address_line1, address_line2, place, postcode, country_code } = feature.properties;
        const addressMapping = {
          street: address_line1,
          streetAdditional: address_line2,
          city: place,
          postal: postcode,
          country: country_code.toUpperCase(),
        };

        Object.entries(addressMapping).forEach(([key, value]) => {
          handleFieldChange({
            form,
            key,
            value,
          });
        });
      }
    },
    [handleFieldChange, form]
  );

  if (isLoading || !module) {
    return null;
  }

  const { AddressAutofill } = module;

  return (
    <StyledAddressAutocompleteWrapper>
      <AddressAutofill
        accessToken={token}
        onRetrieve={handleRetrieve}
        options={form === Forms.shipping ? { country: 'FR' } : {}}
      >
        {content()}
      </AddressAutofill>
    </StyledAddressAutocompleteWrapper>
  );
};
