import { liteClient as algoliasearch } from 'algoliasearch/lite';

import { SEARCH_APP_ID, SEARCH_API_KEY } from './constants';

export const searchClient = algoliasearch(SEARCH_APP_ID, SEARCH_API_KEY);

export const customSearchClient = {
  ...searchClient,
  search(requests) {
    if (
      requests.every(
        ({ params }) =>
          !(
            params?.facetFilters ||
            params?.query ||
            params?.page ||
            params?.ruleContexts?.length ||
            params?.filters
          ) ||
          (params?.query && !params?.query.replace(/[^0-9a-z]/gi, ''))
      )
    ) {
      return Promise.resolve({
        results: requests.map(({ params }) => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          query: params?.query,
        })),
      });
    }
    return searchClient.search(requests);
  },
};
