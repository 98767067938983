import { useMemo, useState } from 'react';
import { StatsigClient } from '@statsig/js-client';

import { STATSIG_CLIENT_API } from '../../api/constants';

type Props = {
  cartID: string;
};

export const useAsyncStatsigClient = ({ cartID }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const client = useMemo(() => {
    const instance = new StatsigClient(
      STATSIG_CLIENT_API,
      {
        userID: cartID,
      },
      {
        environment: {
          tier: process.env.DEPLOYMENT_ENV,
        },
      }
    );

    instance
      .initializeAsync()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });

    return instance;
  }, []);

  return { client, isLoading };
};
