import React, { useEffect, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { RootState } from 'src/shared/store/rootReducer';
import {
  Alert,
  colors,
  DynamicButton,
  DynamicButtonStatus,
  EmailField,
} from '../../../design-system';
import SocialLogin from './SocialLogin';
import { FieldCallback, Forms, FormSignInEmail } from '../../form/types';
import { validate, isCtaActive } from '../../form/utils';
import withForm from '../../form';
import {
  AUTH_BAR_SIGNIN_TITLE,
  AUTH_BAR_TITLE,
  CTA_CONTENT_SIGNIN_EMAIL,
  MSG_ERROR,
} from '../locale';
import { WithFormActions } from '../../form/hocs/withForm';
import useInteractionLock from '../../common/hooks/useInteractionLock';
import { changeTitle } from '../../common/actions';
import { theme } from '../../../design-system/systemprovider';

type Props = {
  checkEmail: (email: string) => void;
  setEmail: (email: string) => void;
  form: FormSignInEmail;
  errLogin: string;
  errSocial: string;
} & WithFormActions;

const StyledCheckEmailExistContainer = styled.div`
  display: grid;
  grid-gap: ${theme.space.l};

  .error-login,
  .social-login-alert,
  .error-social-login {
    margin: 0;
    font-size: 1.2rem;
    color: ${colors.MAIN_GREY};
  }
  .btn-submit-signin {
    margin-top: ${theme.space.l};
  }
`;

const CheckEmailExist = ({
  form,
  errLogin,
  errSocial,
  setFormValues,
  setFormValidation,
  checkEmail,
  setEmail,
}: Props) => {
  useInteractionLock(form.ctaState === DynamicButtonStatus.Loading);

  const dispatch = useDispatch();
  const { ok, message } = useSelector((state: RootState) => state.form.socialLogin.feedback);

  const validateField = ({ key, value }: FieldCallback): string =>
    validate({ value: String(value), key });

  const handleChange = ({ key, value }: FieldCallback) => {
    if (form.validation[key]) {
      setFormValidation({
        form: Forms.signInEmail,
        values: { [key]: validateField({ key, value }) },
      });
    }

    setFormValues({ form: Forms.signInEmail, values: { [key]: value } });
  };

  const validateForm = (): boolean => {
    const { values } = form;
    const newErrMsgs = form.validation;
    const errMsgs = Object.keys(values).map((key) => {
      const errMsg = validateField({ key, value: values[key] });
      newErrMsgs[key] = errMsg;
      setFormValidation({ form: Forms.signInEmail, values: { [key]: errMsg } });
      return errMsg;
    });
    return errMsgs.every((errMsg) => !errMsg);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email } = form.values;
    if (validateForm()) {
      checkEmail(email);
      setEmail(email);
    }
  };

  useEffect(() => {
    dispatch(changeTitle(AUTH_BAR_TITLE));
    return () => {
      handleChange({ key: 'email', value: '' });
      setFormValidation({ form: Forms.signInEmail, values: { email: '' } });
    };
  }, []);

  return (
    <StyledCheckEmailExistContainer>
      {errLogin && (
        <Alert type="error" title={MSG_ERROR}>
          <p className="error-login">{errLogin}</p>
        </Alert>
      )}
      {message && (
        <Alert type={ok ? 'success' : 'error'} title={ok ? AUTH_BAR_SIGNIN_TITLE : MSG_ERROR}>
          <p className="social-login-alert">{message}</p>
          {errSocial && <p className="error-social-login">{errSocial}</p>}
        </Alert>
      )}
      <form onSubmit={handleSubmit} noValidate>
        <EmailField
          onChange={(value) => handleChange({ key: 'email', value })}
          errMsg={form.validation.email}
          value={form.values.email}
          id="signin-email"
          autoComplete="username email"
          autoFocus
        />
        <DynamicButton
          type="submit"
          id="btn-submit-signin"
          className="btn-submit-signin"
          data-testid="btn-submit-signin"
          data={CTA_CONTENT_SIGNIN_EMAIL}
          feedback={form.ctaState}
          disabled={!isCtaActive(form)}
        />
      </form>
      <SocialLogin title="ou connectez-vous avec" />
    </StyledCheckEmailExistContainer>
  );
};

export const CheckEmailExistWithForm = withForm(CheckEmailExist, Forms.signInEmail);
