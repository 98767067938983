import { useEffect } from 'react';

import withAuth from '../hocs/withAuth';
import { getIsClient } from '../../common/utils';

type Props = {
  logout: () => void;
};

const Logout = ({ logout }: Props) => {
  useEffect(() => {
    logout();
    if (getIsClient()) {
      setTimeout(() => {
        window.location.href = '/';
      }, 1000);
    }
  }, []);
  return null;
};

export const LogoutWithAuth = withAuth(Logout);
