import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { Box } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import Menu from './Menu';
import paths from '../../routing/paths';
import accountPaths from '../paths';
import { FeedbackCallback, Forms, FormPersonal } from '../../form/types';
import { logout } from '../../auth/actions';
import { setFeedback } from '../../form/actions';
import { hasToken } from '../../api';
import { User } from '../../auth/types';
import { ErrorUserModal } from './ErrorUserModal';
import { openModal } from '../../common/actions';

type Props = {
  form: FormPersonal;
  logout: () => void;
  setFeedback: (feedback: FeedbackCallback) => void;
  user?: User;
};

export const Account = ({ logout, setFeedback, form, user }: Props) => {
  const [hasSessionConfigured, setHasSessionConfigured] = React.useState(false);
  const { user: userAccount } = useSelector((state: RootState) => state.account);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  React.useEffect(() => {
    setFeedback({
      form: Forms.personal,
      ok: false,
      message: '',
      isDirty: form.feedback.isDirty,
    });
    setFeedback({
      form: Forms.address,
      ok: false,
      message: '',
      isDirty: form.feedback.isDirty,
    });
  }, [pathname]);

  const asyncSession = async () => {
    const isAuthorized = await hasToken();
    const isDeletedAccount = userAccount?.error === 'customer_deleted';
    if (!isAuthorized || isDeletedAccount) {
      dispatch(
        openModal({
          content: <ErrorUserModal isDeletedAccount={isDeletedAccount} isSessionExpiration />,
          preset: 'paymentError',
        })
      );
    } else {
      setHasSessionConfigured(true);
    }
  };

  React.useEffect(() => {
    asyncSession();
  }, [userAccount]);

  if (!hasSessionConfigured) {
    return null;
  }

  if (pathname === paths.ACCOUNT) {
    navigate(paths.ACCOUNT + accountPaths.INFO);
  }

  return (
    <>
      <Helmet>
        <title>Mon compte | Darjeeling</title>
      </Helmet>
      <Box display="flex" flexDirection={['column', 'row']} mx={['m', 'l']}>
        <Box width={[1, 0.3, 0.2]} mb="xl">
          <Menu user={user} logout={logout} />
        </Box>
        <Box width={[1, 0.7, 0.8]} my={['na', 'xxl']}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default connect(
  (state: RootState) => ({
    form: state.form.personal,
    user: state.auth.user,
  }),
  {
    logout,
    setFeedback,
  }
)(Account);
