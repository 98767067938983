import React, { useEffect, FormEvent } from 'react';
import { UnknownAction } from 'redux';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import {
  DynamicButton,
  PasswordField,
  DynamicButtonStatus,
  colors,
  opacities,
  durations,
} from '../../../design-system';
import {
  AUTH_BAR_FORGOT_PASSWORD_TITLE,
  AUTH_BAR_SIGNIN_TITLE,
  AUTH_BAR_TITLE,
  CTA_CONTENT_SIGNIN,
  FORGOT_EMAIL_TEXT,
  MSG_CONNECT_WITH_NEW_CREDENTIALS,
  MSG_ERROR,
  MSG_MODIFY,
  MSG_PASSWORD_UPDATED,
} from '../locale';
import { Forms, FieldCallback, FormSignInPassword } from '../../form/types';
import withForm from '../../form';
import { WithFormActions } from '../../form/hocs/withForm';
import { ParamsLogin } from '../types';
import { validate, isCtaActive } from '../../form/utils';
import useInteractionLock from '../../common/hooks/useInteractionLock';
import { checkEmail, errorLogin, setHasForgotPassword } from '../actions';
import { changeTitle } from '../../common/actions';
import { Alert } from '../../../design-system/alert';
import { theme } from '../../../design-system/systemprovider';

type Props = {
  login: (params: ParamsLogin) => void;
  email: string;
  form: FormSignInPassword;
  errLogin: string;
  comeFromResetPassword?: boolean;
} & WithFormActions;

const StyledSignInContainer = styled.div`
  display: grid;
  grid-gap: ${theme.space.l};

  .success-password-changed,
  .error-login,
  .login-user-email {
    font-size: 1.2rem;
    color: ${colors.MAIN_GREY};
    margin: 0;
  }

  .login-user-email {
    font-size: 1.4rem;
  }

  .email-and-modify-button-block {
    display: flex;
    gap: ${theme.space.m};
  }

  .sign-in-form-block {
    display: grid;
    grid-gap: ${theme.space.l};
  }

  .sigin-in-hidden-user-input {
    display: none;
  }

  .sign-in-modify-email-button,
  .sign-in-forgoten-password-button {
    padding: 0;
    border: none;
    background-color: unset;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 6px;
    color: ${colors.BLACK};
    font-size: 1.4rem;
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
      }
    }
  }
`;

const SignIn = ({
  login,
  email,
  errLogin,
  comeFromResetPassword = false,
  form,
  setFormValues,
  setFormValidation,
}: Props) => {
  useInteractionLock(form.ctaState === DynamicButtonStatus.Loading);
  const dispatch = useDispatch();
  const validateField = ({ key, value }: FieldCallback): string =>
    validate({ value: String(value), key });

  const handleChange = ({ key, value }: FieldCallback) => {
    if (form.validation[key]) {
      setFormValidation({
        form: Forms.signInPassword,
        values: { [key]: validateField({ key, value }) },
      });
    }

    setFormValues({ form: Forms.signInPassword, values: { [key]: value } });
  };

  const validateForm = (): boolean => {
    const { values } = form;
    const newErrMsgs = form.validation;
    const errMsgs = Object.keys(values).map((key) => {
      const errMsg = validateField({ key, value: values[key] });
      newErrMsgs[key] = errMsg;
      setFormValidation({ form: Forms.signInPassword, values: { [key]: errMsg } });
      return errMsg;
    });
    return errMsgs.every((errMsg) => !errMsg);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { password } = form.values;
    if (validateForm()) {
      login({ email, password });
    }
  };

  const onModifyEmailButton = () => {
    dispatch(changeTitle(AUTH_BAR_TITLE));
    dispatch(checkEmail(null) as unknown as UnknownAction);
    dispatch(errorLogin(''));
  };

  const onForgotenPasswordButtonClick = () => {
    dispatch(changeTitle(AUTH_BAR_FORGOT_PASSWORD_TITLE));
    dispatch(setHasForgotPassword(true));
  };

  useEffect(() => {
    handleChange({ key: 'email', value: email });
  }, [email]);

  useEffect(() => {
    dispatch(changeTitle(AUTH_BAR_SIGNIN_TITLE));
    return () => {
      handleChange({ key: 'password', value: '' });
      setFormValidation({ form: Forms.signInPassword, values: { password: '' } });
    };
  }, []);

  return (
    <StyledSignInContainer>
      {comeFromResetPassword && (
        <Alert type="success" title={MSG_PASSWORD_UPDATED}>
          <p id="success-password-changed" className="success-password-changed">
            {MSG_CONNECT_WITH_NEW_CREDENTIALS}
          </p>
        </Alert>
      )}
      {errLogin && (
        <Alert type="error" title={MSG_ERROR}>
          <p id="error-login" className="error-login">
            {errLogin}
          </p>
        </Alert>
      )}
      <div className="email-and-modify-button-block">
        <p className="login-user-email">{email}</p>
        <button
          id="sign-in-modify-email-button"
          className="sign-in-modify-email-button"
          data-testid="sign-in-modify-email-button"
          type="button"
          onClick={onModifyEmailButton}
        >
          {MSG_MODIFY}
        </button>
      </div>
      <form className="sign-in-form-block" onSubmit={handleSubmit}>
        <input
          className="sigin-in-hidden-user-input"
          type="text"
          name="email"
          value={email}
          autoComplete="username"
          readOnly
        />
        <PasswordField
          onChange={(value) => handleChange({ key: 'password', value })}
          errMsg={form.validation.password}
          value={form.values.password}
          id="signin-password"
          autoComplete="current-password"
          autoFocus
        />
        <DynamicButton
          id="btn-submit-signin"
          type="submit"
          data-testid="sign-in-connect-button"
          data={CTA_CONTENT_SIGNIN}
          feedback={form.ctaState}
          disabled={!isCtaActive(form)}
        />
      </form>
      <button
        id="sign-in-forgoten-password-button"
        className="sign-in-forgoten-password-button"
        data-testid="sign-in-forgoten-password-button"
        type="button"
        onClick={onForgotenPasswordButtonClick}
      >
        {FORGOT_EMAIL_TEXT}
      </button>
    </StyledSignInContainer>
  );
};

export const SignInWithForm = withForm(SignIn, Forms.signInPassword);
