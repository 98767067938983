import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';
import styled from '@emotion/styled';

import { Icon, breakpoints, colors, durations, opacities } from '../../../design-system';
import {
  LBL_ACCOUNT_DELETED_ADVICE,
  LBL_ACCOUNT_DELETED_BUTTON_CONTENT,
  LBL_ACCOUNT_DELETED_CONTENT,
  LBL_ACCOUNT_DELETED_TITLE,
  LBL_ERROR_JWT_ADVICE,
  LBL_ERROR_JWT_BUTTON_CONTENT,
  LBL_ERROR_JWT_CONTENT,
  LBL_ERROR_JWT_TITLE,
} from '../locale';
import { closeModal } from '../../common/actions';
import { logout } from '../../auth/actions';

type Props = {
  isSessionExpiration?: boolean;
  isDeletedAccount?: boolean;
};

const StyledContainer = styled.div`
  padding: 16px;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 400;
  color: ${colors.BLACK};

  @media (min-width: ${breakpoints.S}px) {
    padding: 16px 40px 40px 40px;
  }

  .error-modal-close-btn-wrapper {
    margin-right: 0;
    margin-bottom: 8px;
    height: 16px;
    display: flex;
    justify-content: end;

    @media (min-width: ${breakpoints.S}px) {
      margin-right: -24px;
    }
  }

  .error-modal-close-btn {
    border: none;
    background-color: unset;
    padding: 0;
    color: ${colors.BLACK};
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
        cursor: pointer;
      }
    }
  }

  .error-modal-title {
    text-align: center;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin: 0 0 16px 0;
  }

  .error-modal-content {
    margin: 0 0 16px 0;
  }

  .error-modal-advice {
    color: ${colors.MAIN_GREY};
    margin: 0 0 16px 0;
  }

  .error-modal-btn-wrapper {
    display: flex;
    justify-content: center;
  }

  .error-modal-btn {
    padding: 6px 32px;
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 8px;
    background-color: ${colors.BLACK};
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: ${colors.WHITE};
    text-align: center;
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (min-width: ${breakpoints.S}px) {
      width: 240px;
    }

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
        cursor: pointer;
      }
    }
  }
`;

export const ErrorUserModal = ({ isSessionExpiration, isDeletedAccount }: Props) => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    return () => {
      dispatch(logout() as unknown as UnknownAction);
      setTimeout(() => {
        window.location.href = '/?openSideBar=true';
      }, 1000);
    };
  }, []);

  return !isSessionExpiration && !isDeletedAccount ? null : (
    <StyledContainer>
      <div className="error-modal-close-btn-wrapper">
        <button
          className="error-modal-close-btn"
          data-testid={`icon-close-modal-logout-${
            isDeletedAccount ? 'deleted-customer' : 'invalid-refresh-token'
          }`}
          type="button"
          onClick={() => onClose()}
        >
          <Icon name="close" size={16} />
        </button>
      </div>
      <h3 className="error-modal-title">
        {isDeletedAccount ? LBL_ACCOUNT_DELETED_TITLE : LBL_ERROR_JWT_TITLE}
      </h3>
      <p className="error-modal-content">
        {isDeletedAccount ? LBL_ACCOUNT_DELETED_CONTENT : LBL_ERROR_JWT_CONTENT}
      </p>
      <p className="error-modal-advice">
        {isDeletedAccount ? LBL_ACCOUNT_DELETED_ADVICE : LBL_ERROR_JWT_ADVICE}
      </p>
      <div className="error-modal-btn-wrapper">
        <button
          className="error-modal-btn"
          data-testid={`button-modal-logout-${
            isDeletedAccount ? 'deleted-customer' : 'invalid-refresh-token'
          }`}
          type="button"
          onClick={() => onClose()}
        >
          {(isDeletedAccount
            ? LBL_ACCOUNT_DELETED_BUTTON_CONTENT
            : LBL_ERROR_JWT_BUTTON_CONTENT
          ).toUpperCase()}
        </button>
      </div>
    </StyledContainer>
  );
};
