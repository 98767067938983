import { CartItem } from './types';
import { formatPrice } from '../common/utils';
import { Address } from '../account/types';
import {
  NEW_ADDRESS,
  SHIPPING_AMOUNT_FREE,
  SHIPPING_AMOUNT_INVALID,
  ERR_POSTAL_CODE,
} from './locale';
import { RootState } from '../../store/rootReducer';

export function checkCartItemAvailability(item: CartItem): boolean {
  return item.stockQuantity?.stockQuantity > 0 && Boolean(item.status);
}

export function checkCartItemValidity(item: CartItem): boolean {
  return checkCartItemAvailability(item) && item.quantity <= item.stockQuantity?.stockQuantity;
}

export function checkCartValidity(items: CartItem[]): boolean {
  return items.every((item) => checkCartItemValidity(item));
}

export function isCartItemGift(item: CartItem): boolean {
  return Boolean(item.freeProduct);
}

export function formatShippingPrice(price: number): string {
  if (typeof price === 'number') {
    if (price === 0) {
      return SHIPPING_AMOUNT_FREE;
    }
    return formatPrice(price);
  }
  return SHIPPING_AMOUNT_INVALID;
}

export function getAddressOptions(
  addresses: Address[],
  lastUsed?: string,
  allowNewAddressCreation = true
): { value: string; label: string }[] {
  const addressOptions = addresses.map((address) => ({
    value: address.id,
    label: `${address.name ? `${address.name} -` : ''}  ${address.lastName} - ${address.street} - ${
      address.city
    }`,
  }));

  if (lastUsed) {
    addressOptions.push(
      ...addressOptions.splice(
        addressOptions.findIndex((v) => v.value === lastUsed),
        1
      )
    );
    addressOptions.reverse();
  }

  allowNewAddressCreation && addressOptions.push({ value: '', label: NEW_ADDRESS });
  return addressOptions;
}

export function refreshIfNeeded(state: RootState) {
  const { isOutdated } = state.common;
  if (isOutdated) {
    window.location.reload();
  }
}

export function validateMainlandFrancePostalCode(value: string): string {
  if (/^\d{5}$/.test(value)) {
    if (/^9[78].*$/.test(value)) {
      return ERR_POSTAL_CODE;
    }
    return '';
  }
  return ERR_POSTAL_CODE;
}

export function isDateCorrect(date: Date): boolean {
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  return date >= today;
}

export function checkMessageSpaces(message: string, interval: number): string {
  let result = '';
  let count = 0;
  const separableRegex = /[ ,.-]/;
  for (let i = 0; i < message.length; i++) {
    if (separableRegex.test(message[i])) {
      count = 0;
    }
    if (count === interval) {
      result += '-';
      count = 0;
    }

    result += message[i];
    count++;
  }

  return result;
}
