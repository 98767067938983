import React, { useEffect, useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';
import styled from '@emotion/styled';

import { PasswordField, DynamicButton } from '../../../design-system';
import { Forms, FormForgotPassword } from '../../form/types';
import { validate } from '../../form/utils';
import useQuery from '../../common/hooks/useQuery';
import { submitPasswordReset, requestNewPasswordReset } from '../actions';
import useForm from '../../form/hooks/useForm';
import {
  CTA_CONTENT_SUBMIT_PASSWORD,
  LINK_NEW_EMAIL,
  ERR_CODE_EXPIRED,
  MESSAGE_EMAIL_SENT,
  NOTE_PASSWORD,
  AUTH_BAR_TITLE,
  SENDING_A_NEW_EMAIL,
  ATTEMPT_LIMIT_EXCEEDED,
  ERR_CODE_MISMATCH,
  MSG_SEND,
  MSG_ERROR,
} from '../locale';
import { ErrorCodes } from '../types';
import { openSidebar } from '../../common/actions';
import { AuthWithSideBar } from './AuthBar';
import { Alert } from '../../../design-system/alert';
import paths from '../../routing/paths';
import { colors, durations, opacities, theme } from '../../../design-system/systemprovider';
import { AnimatedText } from '../../common/components/AnimatedText';

const StyledResetPasswordContainer = styled.div<{ opacity: number }>`
  display: grid;
  grid-gap: ${theme.space.l};

  .error-reset-password,
  .success-resend-password,
  .success-resend-password-loading {
    font-size: 1.2rem;
    color: ${colors.MAIN_GREY};
    margin: 0;
  }

  .success-resend-password-loading {
    display: flex;
  }

  .btn-submit-reset-password-new-email {
    padding: 0;
    border: none;
    background-color: unset;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 6px;
    color: ${colors.BLACK};
    font-size: 1.4rem;
    transition: opacity ${durations.FOCUS_DELAY}ms ease-in-out;

    @media (hover: hover) {
      &:hover {
        opacity: ${opacities.HOVERED};
      }
    }
  }

  .forgot-password-email {
    font-size: 1.4rem;
    color: ${colors.MAIN_GREY};
    padding-right: ${theme.space.m};
    margin: 0;
  }

  .note-password-message {
    position: relative;
    top: -${theme.space.l};
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${colors.BLACK};
    margin: 0;
    opacity: ${(props) => props.opacity};
  }

  .reset-password-form {
    display: grid;
    grid-gap: ${theme.space.l};
  }
`;

export const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState<boolean | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formState = useForm(Forms.forgotPassword);
  const { actions } = formState;
  const form = formState.form as FormForgotPassword;

  const isNewPasswordResetAction = form.feedback.ok || isLoading;

  const query = useQuery();
  const email = query.get('email') || '';
  const token = query.get('token') || '';

  const clearMessage = () =>
    actions.setFeedback({ form: Forms.forgotPassword, ok: false, message: '' });

  useEffect(() => {
    return () => {
      clearMessage();
      navigate(paths.HOME, { replace: true });
    };
  }, []);

  const validatePassword = (value: string): string => validate({ value, key: 'password' });

  const handleChange = (value: string) => {
    if (form.validation.password) {
      actions.setFormValidation({
        form: Forms.forgotPassword,
        values: {
          password: validatePassword(value),
        },
      });
    }

    actions.setFormValues({ form: Forms.forgotPassword, values: { password: value } });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errMsg = validatePassword(form.values.password);

    actions.setFormValidation({
      form: Forms.forgotPassword,
      values: { password: errMsg },
    });

    if (!errMsg) {
      clearMessage();
      dispatch(
        submitPasswordReset({
          password: form.values.password,
          email,
          token,
        }) as unknown as UnknownAction
      );
    }
  };

  const handleSendNewEmail = async () => {
    setIsLoading(true);
    dispatch(requestNewPasswordReset(email) as unknown as UnknownAction);
  };

  useEffect(() => {
    if (form.feedback.ok) {
      setIsLoading(false);
    }
  }, [form.feedback.ok]);

  useEffect(() => {
    if (form.ctaState === 2) {
      setIsError(true);
    }
    if (form.ctaState === 1) {
      setIsError(false);
      dispatch(
        openSidebar({
          title: AUTH_BAR_TITLE,
          content: <AuthWithSideBar />,
        })
      );
    }
  }, [form]);

  return (
    <StyledResetPasswordContainer opacity={!form.validation.password.length ? 1 : 0}>
      {isError === true && (
        <Alert
          type={isNewPasswordResetAction ? 'success' : 'error'}
          title={isNewPasswordResetAction ? SENDING_A_NEW_EMAIL : MSG_ERROR}
        >
          {form.feedback.message === ErrorCodes.ExpiredCodeException && !isNewPasswordResetAction && (
            <p id="error-reset-password" className="error-reset-password">
              {`${ERR_CODE_EXPIRED}. `}
              <button
                id="btn-submit-reset-password-new-email"
                data-testid="btn-submit-reset-password-new-email"
                className="btn-submit-reset-password-new-email"
                type="button"
                onClick={handleSendNewEmail}
              >
                {LINK_NEW_EMAIL}
              </button>
            </p>
          )}
          {form.feedback.message === ErrorCodes.LimitExceededException && (
            <p id="error-reset-password" className="error-reset-password">
              {ATTEMPT_LIMIT_EXCEEDED}
            </p>
          )}
          {form.feedback.message === ErrorCodes.CodeMismatchException && (
            <p id="error-reset-password" className="error-reset-password">
              {ERR_CODE_MISMATCH}
            </p>
          )}
          {form.feedback.message === ErrorCodes.ExpiredCodeException &&
          isNewPasswordResetAction &&
          form.feedback.ok ? (
            <p id="success-resend-password" className="success-resend-password">
              {MESSAGE_EMAIL_SENT}
            </p>
          ) : isLoading ? (
            <p id="success-resend-password" className="success-resend-password-loading">
              {MSG_SEND}
              <AnimatedText
                text="..."
                id="animated-text-forgot-password-loading"
                fontFamily="MaisonNeue"
                margin="0 0 0 2px"
                animationSpeed={1}
                animationDelay={0.1}
              />
            </p>
          ) : null}
        </Alert>
      )}
      <p className="forgot-password-email">{email}</p>
      <form className="reset-password-form" onSubmit={handleSubmit}>
        <PasswordField
          onChange={(value) => handleChange(value)}
          errMsg={form.validation.password}
          value={form.values.password}
          id="reset-password-password"
          autoComplete="new-password"
          autoFocus
        />
        <p className="note-password-message">{NOTE_PASSWORD}</p>
        <DynamicButton
          type="submit"
          id="btn-submit-reset-password-password"
          data-testid="btn-submit-reset-password-password"
          data={CTA_CONTENT_SUBMIT_PASSWORD}
          feedback={form.ctaState}
        />
      </form>
    </StyledResetPasswordContainer>
  );
};
