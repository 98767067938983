import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { getCookie } from '../../recommendations/utils';
import { APP_VERSION, DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN } from '../../api/constants';

const isDevMode = process.env.DEPLOYMENT_ENV === 'development';
const isProduction = process.env.DEPLOYMENT_ENV === 'production';

export function useCheckUserConsent() {
  useEffect(() => {
    const initializeDatadog = () => {
      const trackingConsent = determineTrackingConsent();
      if (!isDevMode) {
        datadogRum.init({
          applicationId: DATADOG_APPLICATION_ID,
          clientToken: DATADOG_CLIENT_TOKEN,
          site: 'datadoghq.eu',
          service: 'darjeeling',
          env: process.env.DEPLOYMENT_ENV,
          version: APP_VERSION,
          sessionSampleRate: isProduction ? 70 : 100,
          sessionReplaySampleRate: isProduction ? 20 : 100,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          trackViewsManually: true,
          enablePrivacyForActionName: true,
          trackingConsent,
          defaultPrivacyLevel: 'mask-user-input',
        });

        datadogLogs.init({
          clientToken: DATADOG_CLIENT_TOKEN,
          site: 'datadoghq.eu',
          service: 'darjeeling',
          env: process.env.DEPLOYMENT_ENV,
          version: APP_VERSION,
          forwardErrorsToLogs: true,
          sessionSampleRate: isProduction ? 10 : 100,
          trackingConsent,
          forwardConsoleLogs: ['warn', 'error'],
          forwardReports: 'all',
        });
      }
    };

    const determineTrackingConsent = () => {
      const optanonConsent = getCookie('OptanonConsent');
      return optanonConsent?.includes('C0002:1') || optanonConsent?.includes('C0002%3A1')
        ? 'granted'
        : 'not-granted';
    };

    initializeDatadog();

    window.OptanonWrapper = () => {
      window.Optanon?.OnConsentChanged(() => {
        if (window.OptanonActiveGroups?.includes('C0002')) {
          datadogRum.setTrackingConsent('granted');
          datadogLogs.setTrackingConsent('granted');
        } else {
          datadogRum.setTrackingConsent('not-granted');
          datadogLogs.setTrackingConsent('not-granted');
        }
      });
    };
  }, []);
}
