import { useEffect, useState } from 'react';
import {
  AddressAutofillOptions,
  AddressAutofillSuggestionResponse,
  AddressAutofillRetrieveResponse,
} from '@mapbox/search-js-core';

export type AddressAutofillProps = {
  accessToken: string;
  options?: Partial<AddressAutofillOptions>;
  browserAutofillEnabled?: boolean;
  children: React.ReactChild;
  onChange?: (value: string) => void;
  onSuggest?: (res: AddressAutofillSuggestionResponse) => void;
  onSuggestError?: (error: Error) => void;
  onRetrieve?: (res: AddressAutofillRetrieveResponse) => void;
  interceptSearch?: (value: string) => string;
};

type AddressProperties = {
  address_line1: string;
  address_line2?: string;
  place: string;
  postcode: string;
  country_code: string;
};

export type MapboxModule = {
  AddressAutofill: React.ComponentType<{
    accessToken: string;
    onRetrieve: (res: { features: [{ properties: AddressProperties }] }) => void;
  }>;
  config: { accessToken: string };
};

type AddressAutofillModule = {
  AddressAutofill: React.ForwardRefExoticComponent<
    AddressAutofillProps & React.RefAttributes<unknown>
  >;
  config: MapboxModule['config'];
};

export const useAddressAutofillModule = (): {
  module: AddressAutofillModule | null;
  isLoading: boolean;
} => {
  const [module, setModule] = useState<AddressAutofillModule | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('@mapbox/search-js-react')
        .then((loadedModule) => {
          setModule({
            AddressAutofill: loadedModule.AddressAutofill,
            config: loadedModule.config,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error loading AddressAutofill module:', error);
          setIsLoading(false);
        });
    }
  }, []);

  return { module, isLoading };
};
